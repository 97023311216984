import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { useTheme } from "emotion-theming"

import { Container } from "@bigif/components/grid"
import { Medium, Heading } from "@bigif/components/typography"
import Gradient from "@bigif/components/gradient"

const Pool = () => {
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query PoolQuery {
      pool: file(relativePath: { eq: "pool.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Gradient color={theme.colors.primary}>
      <Container>
        <Heading as="h1" fontSize={4}>
          Eaux de surface
        </Heading>
        <Medium>
          L'inondation est le désastre naturel le plus cher en fait de dommages
          à la propriété. Pluies torrentielles, lacs et rivières qui débordent,
          personne n'est à l'abri.
        </Medium>
        <Img fluid={data.pool.childImageSharp.fluid} alt="" />
      </Container>
    </Gradient>
  )
}

export default Pool
