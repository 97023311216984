import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { useTheme } from "emotion-theming"

import { Container } from "@bigif/components/grid"
import { Medium, Heading } from "@bigif/components/typography"
import Gradient from "@bigif/components/gradient"

const Sunken = () => {
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query SunkenQuery {
      sunken: file(relativePath: { eq: "sunken.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Gradient color={theme.colors.secondary}>
      <Container>
        <Heading as="h1" fontSize={4}>
          Tremblement de terre
        </Heading>
        <Medium>
        Le Canada compte en moyenne 300 tremblements de terre par mois. On ne les ressent pas tous mais un seul peut faire chavirer votre vie. Parlez à un courtier d’assurances pour voir si vous êtes protégé où vous vivez.
        </Medium>
        <Img fluid={data.sunken.childImageSharp.fluid} alt="" />
      </Container>
    </Gradient>
  )
}

export default Sunken
