import React from "react"

import { Text, Wrapper, CrackLeft, CrackRight, Inner } from "./styles"

const Cracks = () => (
  <Wrapper>
    <CrackLeft />
    <Inner>
      <Text>
      De nombreux Canadiens ne sont pas bien protégés contre les dommages causés par les eaux de surface ou les tremblements de terre. La plupart des entreprises ne sont pas couvertes contre les cyberattaques.
      </Text>
    </Inner>
    <CrackRight />
  </Wrapper>
)

export default Cracks
