import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { useTheme } from "emotion-theming"

import { Container } from "@bigif/components/grid"
import { Medium, Heading } from "@bigif/components/typography"
import Gradient from "@bigif/components/gradient"

const CyberAttack = () => {
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query CyberAttackQuery {
      pool: file(relativePath: { eq: "cyberattack-july.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Gradient color={theme.colors.primary}>
      <Container>
        <Heading as="h1" fontSize={4}>
          Cyberattaque
        </Heading>
        <Medium>
          Sans la bonne assurance, une entreprise pourrait ne pas se remettre
          des répercussions foudroyantes d’une cyberattaque.
        </Medium>
        <Img fluid={data.pool.childImageSharp.fluid} alt="" />
      </Container>
    </Gradient>
  )
}

export default CyberAttack
