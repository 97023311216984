import React from "react"
import css from "@styled-system/css"
import { useTheme } from "emotion-theming"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { MenuLink } from "@reach/menu-button"

import { Container, Col, Row } from "@bigif/components/grid"
import Card from "@bigif/components/card"
import { Medium, Heading } from "@bigif/components/typography"
import Gradient from "@bigif/components/gradient"

import { Menu, MenuList, MenuButton } from "./styles"

const Broker = () => {
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query PersonQuery {
      person: file(relativePath: { eq: "person.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const handleClick = e => {
    e.preventDefault()
    const { href } = e.currentTarget
    if (typeof window != undefined) {
      if (window.gtag) {
        window.gtag("event", "conversion", {
          send_to: "AW-837012328/Qc4uCK6a4NkCEOiWj48D",
          event_callback: () => {
            if (href) {
              window.location = href
            }
          },
        })
      }

      return false
    }
  }
  return (
    <Gradient
      css={css({ py: [6, 7] })}
      color={theme.colors.secondary}
      position="center top"
    >
      <Container>
        <Card variant="secondary">
          <Row>
            <Col
              width={1 / 6}
              css={css({
                display: ["none", "block"],
              })}
            >
              <Img fluid={data.person.childImageSharp.fluid} alt="" />
            </Col>
            <Col width={[1, 5 / 6]}>
              <Heading as="h1" fontSize={4} mt={0}>
                Trouver un courtier
              </Heading>
              <Medium>
                Parler à un courtier d'assurances est le meilleur moyen de
                protéger votre demeure contre le pire.
              </Medium>
              <Medium>Pas de courtier?</Medium>
              <Menu>
                <MenuButton variant="secondary">Trouver un courtier</MenuButton>
                <MenuList>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="https://ibabc.org/FindBroker"
                  >
                    La Colombie-Britannique
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="https://www.ibaa.ca/page/Consumers"
                  >
                    L'Alberta
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="https://www.ibas.ca/cgi/page.cgi/find-a-broker.html"
                  >
                    La Saskatchewan
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="http://ibam.mb.ca/find-a-broker/"
                  >
                    Le Manitoba
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="https://ontariobrokers.ca/"
                  >
                    L'Ontario
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="https://www.rccaq.com/trouver_courtier_en.html"
                  >
                    Le Québec
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="https://www.nbinsurancebrokers.ca/find-a-broker.html"
                  >
                    Le Nouveau-Brunswick
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="https://www.ibans.com/brokersmap.html"
                  >
                    La Nouvelle-Écosse
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="http://www.ibac.ca/Your-Insurance-Needs/Find-a-Broker/PEI.aspx"
                  >
                    Île-du-Prince-Édouard
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="https://www.iban.ca/brokersmap.html"
                  >
                    La Terre-Neuve-et-Labrador
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="https://www.ibaa.ca/page/Consumers"
                  >
                    Le Yukon
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="https://www.ibaa.ca/page/Consumers"
                  >
                    Les Territoires du Nord-Ouest
                  </MenuLink>
                  <MenuLink
                    onClick={handleClick}
                    as="a"
                    href="https://www.ibaa.ca/page/Consumers"
                  >
                    Le Nunavut
                  </MenuLink>
                </MenuList>
              </Menu>
            </Col>
          </Row>
        </Card>
      </Container>
    </Gradient>
  )
}

export default Broker
