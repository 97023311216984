import React from "react"
import { Element } from "react-scroll"

import Layout from "@bigif/components/layout"

import {
  Broker,
  Cracks,
  LogoAnim,
  Partners,
  Pool,
  Sunken,
  Stats,
  Waves,
  CyberAttack
} from "@bigif/components/panels"

import ToTop from "@bigif/components/to-top"

const IndexPage = () => (
  <>
  <Layout>
    <Element name="big-if">
      <LogoAnim />
      <Cracks />
      <Waves />
    </Element>
    <Element name="disaster">
      <Pool />
      <Sunken />
    </Element>
    <Element name="cyberattack">
      <CyberAttack />
    </Element>
    <Element name="broker">
      <Broker />
    </Element>
    <Element name="partners">
      <Partners />
    </Element>
    <Element name="stats">
      <Stats />
    </Element>
    <ToTop />
  </Layout>
  </>
)

export default IndexPage

