import React from "react"

import { Container } from "@bigif/components/grid"
import House from "@bigif/components/house"
import { Large } from "@bigif/components/typography"

import Wave from "./wave"
import { Wrapper } from "./styles"

const Waves = () => (
  <Wrapper>
    <Container>
      <House>
        <Large>
        La bonne assurance peut vous aider à rebâtir votre maison ou votre entreprise après une catastrophe ou une cyberattaque.
        </Large>
      </House>
    </Container>
    <Wave delay="1s" duration="3s" />
    <Wave delay="2s" duration="4s" />
    <Wave duration="5s" />
  </Wrapper>
)

export default Waves
